import React from 'react';
import { graphql } from 'gatsby';

import { HeadingXL, Layout, SEO, TextBody, HeaderLogo } from '../components';
import { Box } from '@chakra-ui/layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const BlogPost = ({ data }) => {
  const author = data.authorsYaml;
  const fullName = `${author.firstName} ${author.lastName}`;

  return (
    <>
      <SEO title={fullName} />
      <HeaderLogo />
      <Layout>
        <HeadingXL>{fullName}</HeadingXL>
        <Box display="block" boxShadow="2xl" mb="32px">
          <GatsbyImage image={getImage(author.profilePicture)} />
        </Box>
        <TextBody
          dangerouslySetInnerHTML={{
            __html: author.bio.childMarkdownRemark.html,
          }}
        />
      </Layout>
    </>
  );
};

export default BlogPost;

export const query = graphql`
  query ($id: String) {
    authorsYaml(id: { eq: $id }) {
      bio {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      firstName
      lastName
      profilePicture {
        childImageSharp {
          gatsbyImageData(width: 1400, layout: CONSTRAINED)
        }
      }
    }
  }
`;
